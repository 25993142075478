var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{class:{ sticky: _vm.windowScrolled }},[_c('nav',{staticClass:"navbar",class:_vm.lang},[_c('div',[_c('div',[_c('router-link',{staticClass:"navbar-brand",attrs:{"to":_vm.gotohome()},nativeOn:{"click":function($event){return _vm.closeMenu.apply(null, arguments)}}},[_c('img',{staticClass:"logo-desktop pdg",attrs:{"src":require("../assets/logo-dark.svg"),"alt":"logo"}})]),_c('div',{staticClass:"navbar-toggler"},[_c('label',{attrs:{"for":"check"}},[_c('span',{staticClass:"hamburger-menu",attrs:{"id":"check"},on:{"click":_vm.toggleMenu}},[_c('span'),_c('span'),_c('span')])])])],1),_c('div',{class:['inner-container', _vm.menuOpen ? 'show' : '']},[_c('ul',{staticClass:"navbar-nav"},[_vm._l((_vm.menuItems),function(item,index){return _c('li',{key:'menu' + index,class:['nav-item', index === _vm.submenuOpen ? 'active' : '']},[(item.children && item.children.length > 0)?_c('div',{staticClass:"nav-item-content",on:{"click":function($event){return _vm.openSubmenu(index)}}},[_c('span',{staticClass:"nav-link dropdown"},[_vm._v(_vm._s(item.label))])]):_c('div',[_c('router-link',{staticClass:"nav-link",attrs:{"to":/*lang=='it' ? ('/it' + item.url) : */item.url},nativeOn:{"click":function($event){return _vm.closeMenu.apply(null, arguments)}}},[_vm._v(" "+_vm._s(item.label)+" ")])],1),(item.children && item.children.length > 0)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.submenuVisible),expression:"submenuVisible"}],staticClass:"submenu"},[_c('div',{staticClass:"description"},[_c('h2',[_vm._v(_vm._s(item.label))]),(_vm.descriptions[_vm.lang].find(d => d.key == item.label))?_c('p',{class:{
                  'polomenu': (
                    item.label == 'Il Polo'
                    || item.label == 'Area Stampa'
                    || item.label == 'Press Area'
                  )
                },domProps:{"innerHTML":_vm._s(_vm.descriptions[_vm.lang].find(d => d.key == item.label).text)}}):_vm._e()]),_c('div',{staticClass:"list"},[_c('h2',[_vm._v(_vm._s(item.label))]),_c('ul',{class:['', _vm.submenuOpen === index ? 'active' : '']},_vm._l((item.children),function(child,index){return _c('li',{key:index},[_c('div',[_c('router-link',{class:['nav-link-custom', child.label],attrs:{"to":/*lang=='it' ? ('/it' + child.url) : */child.url},nativeOn:{"click":function($event){return _vm.closeMenu.apply(null, arguments)}}},[_vm._v(" "+_vm._s(child.label)+" ")])],1)])}),0)]),(_vm.newsData.length > 0)?_c('div',{staticClass:"news"},[_c('h2',[_vm._v("news")]),_vm._l((_vm.newsData),function(n,index){return _c('router-link',{key:'news-header-' + index,staticClass:"news-item",attrs:{"to":(_vm.lang == _vm.defaultLang ? '' : ('/' + _vm.lang)) + _vm.newsLink(n)}},[_c('p',[_vm._v(_vm._s(n.attributes.title))]),_c('p',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.convertDate(n.attributes.field_news_date))+" ")])])}),_c('a',{staticClass:"link",on:{"click":function($event){return _vm.gotonews()}}},[_vm._v(_vm._s(_vm.lang != _vm.defaultLang ? 'All the News' : 'Tutte le News'))])],2):_vm._e()]):_vm._e()])}),_c('li',{staticClass:"nav-item"},[_c('div',{staticClass:"nav-item-content"},[_c('span',{staticClass:"nav-link"},[_c('div',{staticClass:"lang"},_vm._l((this.$store.state.languages),function(ll,index){return _c('a',{key:'lang' + index,class:{ 'nav-link': true, 'selected': _vm.lang == ll },on:{"click":function($event){return _vm.changelang(ll)}}},[_vm._v(_vm._s(ll)+" ")])}),0)])])])],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }